import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="lazy-loaded-modal"
export default class extends Controller {
  connect () {
    this.initialId = this.element.id

    this.boundBeforeFetchResponse = this.#beforeFetchResponse.bind(this)
    document.addEventListener('turbo:before-fetch-response', this.boundBeforeFetchResponse)

    this.boundFrameRender = this.#frameRender.bind(this)
    document.addEventListener('turbo:frame-render', this.boundFrameRender)
  }

  disconnect () {
    document.removeEventListener('turbo:before-fetch-response', this.boundBeforeFetchResponse)
    document.removeEventListener('turbo:frame-render', this.boundFrameRender)
  }

  // we replace the id of the lazy turbo frame so that its content is correctly replaced by the response
  #beforeFetchResponse (event) {
    if (event.srcElement === this.element) {
      this.element.id = 'modal-content'
    }
  }

  // after render, we set the id of the lazy turbo frame back to its original name to avoid duplicates
  #frameRender (event) {
    if (event.srcElement === this.element) {
      this.element.id = this.initialId
    }
  }
}
