import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="time-range-picker"
export default class extends Controller {
  static targets = ['fromInput', 'fromDropdown', 'toInput', 'toDropdown']

  connect () {
    this.onKeyDownBound = this.#onKeyDown.bind(this)
    this.element.addEventListener('keydown', this.onKeyDownBound)
  }

  disconnect () {
    this.element.removeEventListener('keydown', this.onKeyDownBound)
  }

  fromDropdownTargetConnected () {
    if (this.fromInputTarget.value) {
      this.#findLiContainingText(this.fromInputTarget.value, this.fromDropdownTarget)
    }
  }

  toDropdownTargetConnected () {
    if (this.toInputTarget.value) {
      this.#findLiContainingText(this.toInputTarget.value, this.toDropdownTarget)
    }
  }

  #onKeyDown (event) {
    // if we are on the first input and "Tab" key is pressed, we go to the other one
    if ((event.key === 'Tab') && (event.target === this.fromInputTarget)) {
      event.preventDefault()
      this.toInputTarget.focus()
    }
  }

  #findLiContainingText (text, dropdownTarget) {
    if (text.length === 0) return

    // we scroll to the li containing the text given in parameter
    for (const li of dropdownTarget.querySelectorAll('li')) {
      if (li.textContent.includes(text)) {
        dropdownTarget.scrollTo(0, li.offsetTop)
        return
      }
    }

    // if we don't find any, we try with one less character
    this.#findLiContainingText(text.substring(0, text.length - 1), dropdownTarget)
  }
}
