import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="input-value-setter"
export default class extends Controller {
  setValue (event) {
    const element = this.element.querySelector(`#${event.params.targetId}`)

    element.value = event.params.value
  }
}
