import { Controller } from '@hotwired/stimulus'
import { computePosition, autoUpdate, flip, shift, limitShift, hide } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['menuTemplate']

  show (event) {
    if (this.menuElement || !this.hasMenuTemplateTarget) {
      return
    }

    this.menuElement = this.menuTemplateTarget.content.firstElementChild.cloneNode(true)

    const dropdownContainer = event.params.inline ? this.element : document.getElementById('dropdown-container')
    dropdownContainer.appendChild(this.menuElement)

    this.cleanup = autoUpdate(this.element, this.menuElement, () => {
      computePosition(this.element, this.menuElement, {
        placement: 'bottom-end',
        middleware: [flip(), shift({ limiter: limitShift() }), hide()]
      }).then(({ x, y, middlewareData }) => {
        Object.assign(this.menuElement.style, {
          position: 'absolute',
          left: `${x}px`,
          top: `${y}px`,
          visibility: middlewareData.hide.referenceHidden ? 'hidden' : 'visible'
        })
      })
    })

    this.hideBound = this.hide.bind(this)
    this.menuElement.addEventListener('closed.omnis', this.hideBound)
  }

  hide () {
    this.menuElement.remove()
    this.cleanup()
    this.menuElement = null
  }
}
